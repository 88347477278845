import { ArrowDropDown } from "@mui/icons-material";
import { alpha, Box, FormControl, InputLabel, MenuItem } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { InputStatus } from "../../../../../../../../_common/ui/form-input/form-input";
import { Select } from "../../../../../../../../_common/ui/select/select";
import { formItemValueName } from "../../../../configuration-schema/utils/form-item-value-name";
import { HelpButton } from "../../../../ui/help-button";
import { ItemReadOnlyValue } from "../../../../ui/item-read-only-value";
import { ItemValidationMessage } from "../../../../ui/item-validation-message";
import { helperTextId } from "../../../../utils/helper-text-id";
import { itemId } from "../../../../utils/item-id";

const DROPDOWN_WIDTH = "320px";

interface DropdownItemProps<T extends string | number> {
  first: boolean;
  disabled?: boolean;
  isEditMode: boolean;
  isUpdating: boolean;
  name: string;
  label: string;
  toggleDescription: (() => void) | undefined;
  items: { value: T; label: string }[];
  isDescriptionVisible: boolean;
  validationDependency: string[] | undefined;
}

export function DropdownItem<T extends string | number>({
  first,
  name,
  label,
  disabled,
  toggleDescription,
  isEditMode,
  isUpdating,
  items,
  isDescriptionVisible,
  validationDependency,
}: DropdownItemProps<T>) {
  const {
    formState: { isSubmitted },
    trigger,
  } = useFormContext();

  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name: formItemValueName(name) });
  const { classes } = useStyles({ status: "primary" });

  return isEditMode ? (
    <>
      <FormControl disabled={disabled || isUpdating} className={classes.formControl}>
        {!first && (
          <Box className={classes.labelContainer}>
            <InputLabel>{label}</InputLabel>
            {toggleDescription && (
              <HelpButton
                itemLabel={label}
                aria-controls={helperTextId(name)}
                aria-expanded={isDescriptionVisible}
                onClick={toggleDescription}
              />
            )}
          </Box>
        )}
        <Select
          {...field}
          onChange={e => {
            onChange(e);
            if (isSubmitted && validationDependency)
              validationDependency.forEach(name => void trigger(formItemValueName(name)));
          }}
          id={`${itemId(name)}`}
          aria-label={first ? label : undefined}
          variant="standard"
          labelId={itemId(name)}
          className={classes.select}
          IconComponent={ArrowDropDown}
          MenuProps={{
            classes: {
              paper: classes.selectMenu,
            },
          }}
        >
          {items.map(item => (
            <MenuItem key={item.value.toString()} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ItemValidationMessage itemName={name} error={error} />
    </>
  ) : (
    <ItemReadOnlyValue value={items.find(item => item.value === field.value)?.label ?? ""} />
  );
}

const boxShadow = "none!important";

const useStyles = makeStyles<{ status?: InputStatus | "primary" }>()((theme, { status = "primary" }) => ({
  formControl: {
    width: "100%",
    gap: theme.spacing(0.5),
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
  },
  select: {
    width: DROPDOWN_WIDTH,
    "& > div[role='button']": {
      paddingLeft: theme.spacing(2),
    },
    "&.MuiInputBase-root": {
      borderRadius: theme.spacing(0.5),
      "&:hover": {
        border: `1px solid ${theme.palette[status].main}`,
        backgroundColor: alpha(theme.palette[status].main, 0.08),
        boxShadow,
      },
      "&.Mui-focused": {
        border: `1px solid ${theme.palette.primary.main}`,
        outline: `1px solid ${theme.palette.primary.main}`,
        boxShadow,
        "&:hover": {
          boxShadow,
        },
        "&:active": {
          border: `1px solid ${theme.palette[status].main}`,
          outline: `none`,
          boxShadow,
        },
      },
      "&.Mui-disabled": {
        border: `1px solid ${alpha(theme.palette[status].main, 0.5)}`,
        "&:hover": {
          border: `1px solid ${alpha(theme.palette[status].main, 0.5)}`,
        },
      },
    },
  },
  selectMenu: {
    margin: theme.spacing(1, 0),
    width: DROPDOWN_WIDTH,
    "& li": {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  statusIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  helperText: {
    display: "flex",
    width: "100%",
    color: status ? undefined : "rgba(68, 68, 68, 0.79)",
    fontSize: theme.typography.pxToRem(14),
    justifyContent: "space-between",
  },
}));
