import { Download as DownloadIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../../../_common/datadog";
import { useBytesFormatter } from "../../../../../../../_common/hooks/use-bytes-formatter";
import { useCountdownTimer } from "../../../../../../../_common/hooks/use-countdown-timer";
import { GetShieldingsListItem } from "../../../api/get-shieldings-list-item";
import { DownloadShieldingButton } from "../../../ui/download-shielding-button/download-shielding-button";

type DownloadLinkExpandedRowProps = {
  shielding: GetShieldingsListItem;
  onExpiry: () => void;
};

export const DownloadLinkExpandedRow = ({ shielding, onExpiry }: DownloadLinkExpandedRowProps) => {
  const bytesFormatter = useBytesFormatter();
  const { classes } = useStyles();

  // prevent triggering the row event
  const countdownTimer = useCountdownTimer({ expiryDate: shielding.shieldingExpiryTime!, onExpiry });

  return (
    <Typography
      className={classes.container}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {}
      <FormattedMessage
        id={"component.shieldings-list.download-package"}
        values={{
          downloadLink: (
            <DownloadShieldingButton
              className={classes.downloadLink}
              fileName={shielding.shieldingOutputPackageName!}
              startIcon={<DownloadIcon />}
              shieldingId={shielding.id}
              onClick={() => {
                addAction(AppShieldingActions.DownloadShielding, AppShieldingFeatures.ShieldingsList);
              }}
              key="downloadLink"
            />
          ),
          size: bytesFormatter(shielding?.shieldingOutputPackageSize ?? 0),
          countdownTimer: (
            <span className={classes.countdownTimer} key="countdownTImer">
              {countdownTimer}
            </span>
          ),
        }}
      />
    </Typography>
  );
};

const useStyles = makeStyles()(theme => ({
  container: {
    textAlign: "center",
    padding: theme.spacing(2.5),
    background: "rgba(0, 0, 0, 0.04)",
  },
  countdownTimer: {
    textAlign: "left",
    width: theme.spacing(7.5),
    display: "inline-block",
  },
  downloadLink: {
    wordBreak: "break-word",
    padding: 0,
    marginTop: theme.spacing(-0.375),
    marginRight: theme.spacing(0.5),
    "&:focus-visible": {
      outline: `solid 2px ${theme.palette.primary.main}`,
    },
  },
}));
