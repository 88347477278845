import { DataType } from "@mob/shielder-metadata";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { ChangeEvent, useCallback } from "react";
import { useController, useFormContext } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../../../../../../../_common/i18n/hooks/use-translate.hook";
import { formItemValueName } from "../../../../configuration-schema/utils/form-item-value-name";
import { HelpButton } from "../../../../ui/help-button";
import { ItemReadOnlyValue } from "../../../../ui/item-read-only-value";
import { ItemValidationMessage } from "../../../../ui/item-validation-message";
import { helperTextId } from "../../../../utils/helper-text-id";
import { itemId } from "../../../../utils/item-id";

interface RadioGroupItemProps<T extends string | number | boolean> {
  first: boolean;
  disabled?: boolean;
  isEditMode: boolean;
  isUpdating: boolean;
  name: string;
  label: string;
  toggleDescription: (() => void) | undefined;
  items: { value: T; label: string }[];
  dataType: DataType.String | DataType.Integer | DataType.Boolean;
  isDescriptionVisible: boolean;
  validationDependency: string[] | undefined;
}

export function RadioGroupItem<T extends string | number | boolean>({
  first,
  name,
  label,
  disabled,
  toggleDescription,
  isEditMode,
  isUpdating,
  items,
  dataType,
  isDescriptionVisible,
  validationDependency,
}: RadioGroupItemProps<T>) {
  const {
    trigger,
    formState: { isSubmitted },
  } = useFormContext();
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name: formItemValueName(name) });
  const { classes } = useStyles();
  const t = useTranslate();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      switch (dataType) {
        case DataType.Boolean:
          onChange(e.target.value === "true");
          break;
        case DataType.Integer:
          onChange(parseInt(e.target.value));
          break;
        default:
          onChange(e.target.value);
      }
      if (isSubmitted && validationDependency)
        validationDependency.forEach(name => void trigger(formItemValueName(name)));
    },
    [dataType, isSubmitted, validationDependency, onChange, trigger],
  );

  return isEditMode ? (
    <>
      <FormControl disabled={disabled || isUpdating} className={classes.formControl}>
        {!first && (
          <Box className={classes.labelContainer}>
            <FormLabel className={classes.label}>{label}</FormLabel>{" "}
            {toggleDescription && (
              <HelpButton
                itemLabel={label}
                aria-controls={helperTextId(name)}
                aria-expanded={isDescriptionVisible}
                onClick={toggleDescription}
              />
            )}
          </Box>
        )}
        <RadioGroup
          id={itemId(name)}
          row
          {...field}
          onChange={handleChange}
          className={classes.radioGroup}
          tabIndex={0}
        >
          {items.map(item => (
            <Box key={item.value.toString()} className={classes.radioItem}>
              <FormControlLabel
                className={classes.label}
                value={item.value}
                control={<Radio />}
                label={item.label}
                aria-label={t("component.configuration-item.option.aria-label", { name: label, option: item.label })}
              />
            </Box>
          ))}
        </RadioGroup>
      </FormControl>
      <ItemValidationMessage itemName={name} error={error} />
    </>
  ) : (
    <ItemReadOnlyValue value={items.find(item => item.value === field.value)?.label ?? ""} />
  );
}

const useStyles = makeStyles()(theme => ({
  formControl: {
    flexDirection: "column",
    width: "100%",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
    "& .MuiFormLabel-root": {
      color: theme.palette.text.primary,
      "&.Mui-focused": {
        color: theme.palette.text.primary,
      },
    },
  },
  label: {
    wordBreak: "break-word",
  },
  radioGroup: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiRadio-root": {
      marginRight: theme.spacing(1),
    },
    "& .Mui-checked": {
      color: `${theme.palette.primary.main} !important`,
    },
    "& label": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  radioItem: {
    display: "flex",
    alignItems: "center",
    width: "50%",
  },
}));
