import { ArrowBack as ArrowBackIcon, Close as CloseIcon } from "@mui/icons-material";
import { alpha, AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { MouseEvent, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

import { SIDE_BAR_GRID_CLOSED_WIDTH, SIDE_BAR_GRID_OPENED_WIDTH, TOP_BAR_GRID_HEIGHT } from "../constants";
import { ContextMenu, ContextMenuProps } from "../context-menu/context-menu";
import { FixedWidthSkeleton } from "../skeleton/fixed-width-skeleton";
import { sideBarTransition, TransitionType } from "../theme/transitions";
import { ApplicationBarIconButtonType } from "./types";

export interface ApplicationBarProps {
  iconButtonType?: ApplicationBarIconButtonType;
  iconButtonClickHandler?: (e: MouseEvent<HTMLButtonElement>) => void;
  iconButtonAriaLabel?: string;
  isLoading?: boolean;
  title: string | undefined;
  titlePostfix?: ReactNode;
  children: ReactNode;
  contextMenu?: ContextMenuProps;
  sideBarOpen: boolean;
}

export const ApplicationBar = ({
  iconButtonType,
  iconButtonClickHandler,
  isLoading,
  title,
  titlePostfix,
  children,
  contextMenu,
  iconButtonAriaLabel,
  sideBarOpen,
}: ApplicationBarProps) => {
  const { classes } = useStyles({ sideBarOpen });

  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.leftContainer}>
          {iconButtonType && iconButtonClickHandler && (
            <IconButton
              className={classes.iconButton}
              data-testid={`appbar-icon-button-${iconButtonType}`}
              onClick={iconButtonClickHandler}
              aria-label={iconButtonAriaLabel}
            >
              {iconButtonIcon[iconButtonType]}
            </IconButton>
          )}
          {isLoading ? (
            <FixedWidthSkeleton relativeWidth={4} data-testid="appbar-title-skeleton-loader" />
          ) : (
            <>
              {title && (
                <Typography className={classes.title} variant="h5" component="h1" title={title}>
                  {title}
                </Typography>
              )}
              {titlePostfix}
            </>
          )}
        </Box>

        <Box className={classes.rightContainer} display="flex" alignItems="center">
          {children}
          {contextMenu &&
            (isLoading ? (
              <FixedWidthSkeleton relativeWidth={0.5} data-testid="appbar-context-menu-skeleton-loader" />
            ) : (
              <ContextMenu {...contextMenu} data-testid="context-menu" outlined />
            ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const iconButtonIcon: Record<ApplicationBarIconButtonType, ReactNode> = {
  [ApplicationBarIconButtonType.Back]: <ArrowBackIcon />,
  [ApplicationBarIconButtonType.Close]: <CloseIcon />,
};

const useStyles = makeStyles<{ sideBarOpen: boolean }>()((theme, { sideBarOpen }) => {
  const sideBarWidth = sideBarOpen ? SIDE_BAR_GRID_OPENED_WIDTH : SIDE_BAR_GRID_CLOSED_WIDTH;
  const sideBarTransitionType = sideBarOpen ? TransitionType.Open : TransitionType.Close;

  return {
    appBar: {
      overflow: "hidden",
      width: `calc(100% - ${theme.spacing(sideBarWidth)})`,
      left: theme.spacing(sideBarWidth),
      transition: sideBarTransition(theme, sideBarTransitionType, "all"),
      "&.MuiAppBar-colorPrimary": {
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
      },
    },
    toolbar: {
      "&.MuiToolbar-root": {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(0, 1.2, 0, 2.5),
        height: theme.spacing(TOP_BAR_GRID_HEIGHT),
        borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
      },
    },
    leftContainer: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
      fontWeight: 600,
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        color: theme.palette.grey[600],
      },
    },
    iconButton: {
      marginRight: theme.spacing(2.8),
      fontWeight: 600,
    },
    title: {
      fontWeight: 600,
      color: theme.palette.text.primary,
      marginRight: theme.spacing(1),
      ...theme.mixins.lineClamp(2),
    },
    rightContainer: {
      "&.MuiBox-root > *": {
        marginLeft: theme.spacing(1),
        height: theme.spacing(5.5),
        boxShadow: "none",
      },
    },
  };
});
