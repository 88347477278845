import { Backdrop, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { Alert } from "../alert/alert";
import { Anchor } from "../link/anchor";
import { DEFAULT_SHADOW } from "../theme/theme";
import { useCookieConsent } from "./use-cookie-consent";

export const CookieConsent = () => {
  const t = useTranslate();
  const { classes } = useStyles();
  const { showConsent, setConsent } = useCookieConsent();

  const handleAccept = (accept: boolean) => () => {
    setConsent(accept);
  };

  return (
    <Backdrop aria-hidden={false} open={showConsent} className={classes.backdrop}>
      <Alert
        className={classes.alert}
        type="warning"
        description={
          <Typography>
            <FormattedMessage
              id="component.layout.cookie-consent"
              values={{
                link: text => (
                  <Anchor target="_blank" href="https://www.onespan.com/cookie-policy" key="link">
                    {text}
                  </Anchor>
                ),
              }}
            />
          </Typography>
        }
        primaryButton={{
          label: t("component.layout.cookie-consent.accept-button"),
          onClick: handleAccept(true),
        }}
        secondaryButton={{
          label: t("component.layout.cookie-consent.reject-button"),
          onClick: handleAccept(false),
          variant: "outlined",
        }}
      />
    </Backdrop>
  );
};

const useStyles = makeStyles()(theme => ({
  backdrop: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflow: "hidden",
    zIndex: 5000,
  },
  alert: {
    width: "45%",
    position: "fixed",
    margin: theme.spacing(2.5),
    bottom: 0,
    right: 0,
    boxShadow: DEFAULT_SHADOW,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.down("md")]: {
      width: `calc(100% - ${theme.spacing(5)})`,
    },
  },
}));
