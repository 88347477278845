import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../../_common/datadog";
import { useTranslate } from "../../../../../../_common/i18n/hooks/use-translate.hook";
import { Alert } from "../../../../../../_common/ui/alert/alert";
import { ApplicationBarIconButtonType } from "../../../../../../_common/ui/application-bar/types";
import { CodeViewer } from "../../../../../../_common/ui/code-viewer/code-viewer";
import { Layout } from "../../../../../../_common/ui/layout/layout";
import { DebugModeTag } from "../../../../_common/ui/debug-mode-tag";
import { useGetProject } from "../../../projects/api/get-project";
import { useGetShielding } from "../../api/get-shielding";
import { useGetShieldingProgress } from "../../api/get-shielding-progress/use-get-shielding-progress";
import { ApiShieldingStatus } from "../../api/types";
import { ShieldingSourceTag } from "../shielding-source-tag";
import { ShieldingHeaderPanel } from "./components/shielding-header-panel";

export function ShieldingDetailsPage() {
  const t = useTranslate();
  const navigate = useNavigate();
  const shieldingId = useParams<"shieldingId">().shieldingId!;
  const projectId = useParams<"projectId">().projectId!;

  const { data: project } = useGetProject(projectId);
  const { data: shielding } = useGetShielding({ shieldingId, project });

  const { data: shieldingProgress } = useGetShieldingProgress({ shielding, project });
  const isFailed =
    shielding &&
    [ApiShieldingStatus.Aborted, ApiShieldingStatus.Failed, ApiShieldingStatus.Timeout].includes(
      shielding.shieldingStatus,
    );

  const navigateBack = () => {
    addAction(AppShieldingActions.NavigateBack, AppShieldingFeatures.ShieldingsDetails);
    navigate("../..");
  };

  return (
    <Layout
      title={shielding?.shieldingInputFileName}
      titlePostfix={
        <Box gap={1} display="flex">
          <ShieldingSourceTag source={shielding?.shieldingSource} />
          <DebugModeTag show={shielding?.debugMode ?? false} />
        </Box>
      }
      iconButtonType={ApplicationBarIconButtonType.Back}
      iconButtonClickHandler={navigateBack}
      isLoading={!project || !shielding}
      contentBoxProps={{ flexDirection: "column" }}
    >
      {isFailed && (
        <Box mb={2.5}>
          <Alert
            type="error"
            title={t(`component.shielding.error-alert.${shielding.shieldingStatus}.title`)}
            description={t("component.shielding.error-alert.description")}
          />
        </Box>
      )}
      <ShieldingHeaderPanel
        isLoading={!project || !shielding}
        shielding={shielding}
        shieldingProgress={shieldingProgress}
      />
      <CodeViewer code={shielding?.shieldingLogs} flex={1} />
    </Layout>
  );
}
