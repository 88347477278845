import { Box, ButtonProps } from "@mui/material";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMountedState } from "react-use";
import { makeStyles } from "tss-react/mui";

import { MutationError } from "../../api/errors/mutation-error";
import { useServerFormValidationErrors } from "../../api/errors/use-server-form-validation-errors";
import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { Alert } from "../alert/alert";
import { ButtonProgress } from "../button-progress/button-progress";
import { Panel } from "../panel/panel";

const enum MutationStatus {
  NotMutated,
  Successful,
}

export type OnSubmitCallback<TFieldValues, TResult = unknown> = (input: TFieldValues) => Promise<TResult> | TResult;

interface SideFormProps<TFieldValues extends FieldValues, TResult> {
  children?: ReactNode;
  formProps: UseFormReturn<TFieldValues>;
  onSubmit: OnSubmitCallback<TFieldValues, TResult>;
  isLoading?: boolean;
  translationComponentName: string;
  mutationErrors?: MutationError<TFieldValues> | null;
  successNavigationPath: string | ((response: TResult) => string);
  cancelNavigationPath?: string;
  submitButtonProps?: ButtonProps & { label?: string };
  showAlertAlways?: boolean;
  alertType?: "error" | "warning";
}

export function SideForm<TFieldValues extends FieldValues, TResult>({
  formProps,
  children,
  isLoading = false,
  onSubmit,
  translationComponentName,
  mutationErrors,
  successNavigationPath,
  cancelNavigationPath,
  submitButtonProps,
  showAlertAlways,
  alertType = "error",
}: SideFormProps<TFieldValues, TResult>) {
  const t = useTranslate();
  const isMounted = useMountedState();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  useEffect(() => setOpen(true), []);
  const mutationStatusRef = useRef(MutationStatus.NotMutated);
  const submitResponseRef = useRef<TResult | undefined>(undefined);
  const { isSubmitting, errors } = formProps.formState;

  const submitHandler: SubmitHandler<TFieldValues> = async input => {
    try {
      submitResponseRef.current = await onSubmit(input);
      mutationStatusRef.current = MutationStatus.Successful;

      if (isMounted()) {
        setOpen(false);
      }
    } catch (_) {
      /* empty */
    }
  };

  const serverGeneralErrorMessage = useServerFormValidationErrors(formProps, mutationErrors);

  const onExited = () => {
    if (mutationStatusRef.current !== MutationStatus.Successful) {
      navigate(cancelNavigationPath || "..");
    } else {
      const successPath =
        typeof successNavigationPath === "function"
          ? successNavigationPath(submitResponseRef.current!)
          : successNavigationPath;
      navigate(successPath);
    }
  };
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <Panel
      data-testid="side-form"
      heading={t(`component.${translationComponentName}.panel.heading`)}
      width={468}
      cancelButton={{ label: t("word.cancel"), disabled: isSubmitting }}
      confirmButton={{
        label: t(`component.${translationComponentName}.form.submit`),
        ...submitButtonProps,
        className: submitButtonProps?.className,
        sx: { minWidth: 100 },
        type: "submit",
        form: "form-id",
        disabled: isSubmitting || submitButtonProps?.disabled,
        startIcon: isSubmitting ? <ButtonProgress /> : submitButtonProps?.startIcon,
      }}
      isLoading={isLoading}
      open={open}
      onClose={onClose}
      SlideProps={{ onExited }}
      disableBackdropClick
    >
      <FormProvider {...formProps}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form id="form-id" onSubmit={formProps.handleSubmit(submitHandler)} className={classes.form}>
          {serverGeneralErrorMessage && (
            <Box className={classes.alertContainer}>
              <Alert type={alertType} description={serverGeneralErrorMessage} />
            </Box>
          )}
          {(Boolean(Object.keys(errors).length) || showAlertAlways) && (
            <Box className={classes.alertContainer}>
              <Alert type={alertType} description={t(`component.${translationComponentName}.alert.description`)} />
            </Box>
          )}
          <Box className={classes.formContainer}>{children}</Box>
        </form>
      </FormProvider>
    </Panel>
  );
}

const useStyles = makeStyles()(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    overflowY: "hidden",
  },
  alertContainer: {
    padding: theme.spacing(2.5),
  },
  formContainer: {
    padding: theme.spacing(2.5),
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxHeight: "100%",
    overflowY: "auto",
  },
}));
