import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../_common/datadog";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { Alert } from "../../../../../_common/ui/alert/alert";
import { RevokeAccessApiKeyDialog } from "../../api-access/components/revoke-access-api-key-dialog";
import { ConfigStatus } from "../../configurations/api/types/config-status";
import { GetProject } from "../../projects/api/get-project";
import { AlertType } from "../types/alert-type";
import { ShielderStatus } from "../types/shielder-status";

type UnsupportedShielderAlertProps = {
  project?: GetProject;
  type: AlertType;
  selectVersionPath: string;
  unsupportedShielder: boolean;
  deletedShielder: boolean;
};

export const UnsupportedOrDeletedShielderVersionAlert = ({
  project,
  type,
  selectVersionPath,
  unsupportedShielder,
  deletedShielder,
}: UnsupportedShielderAlertProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  const navigateToSelectVersion = useCallback(() => {
    navigate(selectVersionPath);
  }, [navigate, selectVersionPath]);

  const handleChangeShieldVersion = useCallback(() => {
    const feature =
      type === AlertType.ProjectDetails ? AppShieldingFeatures.ProjectsDetails : AppShieldingFeatures.Configurations;

    if (project?.apiKeyId) {
      setOpenDialog(true);
      addAction(AppShieldingActions.OpenRevokeConfigurationKey, feature);
    } else {
      navigateToSelectVersion();
      addAction(AppShieldingActions.OpenChangeShielder, feature);
    }
  }, [navigateToSelectVersion, project?.apiKeyId, type]);

  const hasConfig = project?.configStatus !== ConfigStatus.None;
  const isUnsupportedShielder = project?.shielderStatus === ShielderStatus.Unsupported;
  const description =
    type === AlertType.ProjectDetails
      ? hasConfig
        ? t("common.shielder-status.alert.unsupported-or-deleted-shield-version.with-configuration.description")
        : t("common.shielder-status.alert.unsupported-or-deleted-shield-version.no-configuration.description")
      : isUnsupportedShielder
        ? t("common.shielder-status.alert.unsupported-shield-version.configuration-edit.description")
        : t("common.shielder-status.alert.deleted-shield-version.configuration-edit.description");

  return (
    <>
      <Alert
        type="error"
        title={
          (unsupportedShielder && t("common.shielder-status.alert.unsupported-shield-version.title")) ||
          (deletedShielder && t("common.shielder-status.alert.deleted-shield-version.title"))
        }
        description={description}
        primaryButton={{
          label: t("component.basic-info.change-shield-version.button"),
          onClick: handleChangeShieldVersion,
        }}
      />
      <RevokeAccessApiKeyDialog
        open={openDialog}
        project={project}
        onRevoked={navigateToSelectVersion}
        onCancel={() => setOpenDialog(false)}
        title={t("component.revoke-api-key.before-change-shield-version.dialog.title")}
        content={t("component.revoke-api-key.before-change-shield-version.dialog.message")}
      />
    </>
  );
};
