import NotFoundIcon from "@mui/icons-material/BrowserNotSupported";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { IS_PROD } from "../../env-vars";
import { useHasTranslationKey } from "../../i18n/hooks/use-has-translation-key";
import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { CodeViewer } from "../code-viewer/code-viewer";
import { EmptyState } from "../empty-state/empty-state";
import { Anchor } from "../link/anchor";

const SUPPORT_URL = "https://support.onespan.com/csm";

interface ErrorPageProps {
  error: unknown;
}
export const ErrorPage = ({ error }: ErrorPageProps) => {
  const t = useTranslate();
  const errorMessage: string = (error as Error)?.stack?.toString() || (error as string).toString() || "";
  const { classes } = useStyles();

  const productPath = window.location.pathname.split("/")[1];
  const productKey = `component.empty-state.error-boundary.description.${productPath}`;
  const hasProductKey = useHasTranslationKey()(`component.empty-state.error-boundary.description.${productPath}`);

  return (
    <Box className={classes.container}>
      <EmptyState
        isFullPage={false}
        icon={NotFoundIcon}
        title={t("component.empty-state.error-boundary.title")}
        description={
          <>
            <FormattedMessage
              id={hasProductKey ? productKey : "component.empty-state.error-boundary.description"}
              values={{
                supportLink: text => (
                  <Anchor href={SUPPORT_URL} target="_blank" rel="noreferrer" key="supportLink">
                    {text}
                  </Anchor>
                ),
                homePageLink: text => <Anchor href={`/${productPath}`}>{text}</Anchor>,
              }}
            />
            {!IS_PROD && errorMessage && (
              <Box maxHeight="200px" overflow="auto" mt={2} px={2} display="flex">
                <CodeViewer code={errorMessage} />
              </Box>
            )}
          </>
        }
        buttonProps={{
          onClick: () => window.location.reload(),
          label: t("component.empty-state.error-boundary.reload-button"),
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles()({
  container: {
    position: "fixed",
    inset: 0,
  },
});
