import { Edit as EditIcon } from "@mui/icons-material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../_common/datadog";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { ApplicationBarPrimaryButton } from "../../../../../_common/ui/application-bar/application-bar-primary-button";
import { RevokeAccessApiKeyDialog } from "../../api-access/components/revoke-access-api-key-dialog";
import { GetProject } from "../../projects/api/get-project";

interface ConfigurationEditButtonProps {
  project: GetProject | undefined;
  disabled: boolean;
  hasApiKey: boolean;
}
export const ConfigurationEditButton = ({ disabled, hasApiKey, project }: ConfigurationEditButtonProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleStartEditConfiguration = useCallback(() => {
    addAction(AppShieldingActions.OpenEditConfiguration, AppShieldingFeatures.Configurations);
    navigate("edit");
  }, [navigate]);

  const handleRevokeConfigurationKey = useCallback(() => {
    addAction(AppShieldingActions.OpenRevokeConfigurationKey, AppShieldingFeatures.Configurations);
    setIsConfirmationOpen(true);
  }, []);

  return (
    <>
      <ApplicationBarPrimaryButton
        type="button"
        disabled={disabled}
        startIcon={<EditIcon />}
        onClick={hasApiKey ? handleRevokeConfigurationKey : handleStartEditConfiguration}
        aria-label={t("common.edit-configuration")}
      >
        {t("word.edit")}
      </ApplicationBarPrimaryButton>
      <RevokeAccessApiKeyDialog
        open={isConfirmationOpen}
        project={project}
        onRevoked={handleStartEditConfiguration}
        onCancel={() => setIsConfirmationOpen(false)}
        title={t("component.edit-configuration-dialog.api-key-present.title")}
        content={t("component.edit-configuration-dialog.api-key-present.text")}
      />
    </>
  );
};
