import { Box, Button, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../../../_common/datadog";
import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { CardSection } from "../../../../../../../_common/ui/card/card-section";
import { FixedWidthSkeleton } from "../../../../../../../_common/ui/skeleton/fixed-width-skeleton";
import { FullWidthSkeleton } from "../../../../../../../_common/ui/skeleton/full-width-skeleton";
import { RevokeAccessApiKeyDialog } from "../../../../api-access/components/revoke-access-api-key-dialog";
import { ShielderLabel } from "../../../../shielders/ui/shielder-label";
import { GetProject } from "../../../api/get-project";
import { DownloadSdkButton } from "./download-sdk-button";

interface ShielderInfoProps {
  project?: GetProject;
  isLoading?: boolean;
}

export const ShielderInfo = ({ project, isLoading }: ShielderInfoProps) => {
  const navigate = useNavigate();
  const t = useTranslate();
  const { classes } = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const navigateToSelectVersion = useCallback(() => {
    navigate("select-version");
  }, [navigate]);

  const handleChangeShieldVersion = useCallback(() => {
    addAction(AppShieldingActions.OpenChangeShielder, AppShieldingFeatures.ProjectsDetails);
    return project?.apiKeyId ? setOpenDialog(true) : navigateToSelectVersion();
  }, [navigateToSelectVersion, project?.apiKeyId]);

  return (
    <>
      <CardSection
        borderTop
        title={
          isLoading ? (
            <FixedWidthSkeleton width="100px" data-testid="basic-info-shielder-title-skeleton-loader" />
          ) : (
            t("word.shield")
          )
        }
        description={
          isLoading ? (
            <FullWidthSkeleton data-testid="basic-info-shielder-content-skeleton-loader" />
          ) : (
            project && (
              <>
                <ShielderLabel
                  platform={project.shielderPlatform}
                  version={project.shielderVersion}
                  status={project.shielderStatus}
                  hidePlatformName
                />
                <Box mt={1} display="flex" gap={2.5}>
                  <Typography component="span">
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleChangeShieldVersion}
                      className={classes.link}
                      role="link"
                      size="small"
                    >
                      {t("component.basic-info.change-shield-version.button")}
                    </Button>
                  </Typography>
                  <Typography component="span">
                    <DownloadSdkButton shielder={project} className={classes.link} />
                  </Typography>
                </Box>
              </>
            )
          )
        }
      />
      <RevokeAccessApiKeyDialog
        open={openDialog}
        project={project}
        onRevoked={() => navigateToSelectVersion()}
        onCancel={() => setOpenDialog(false)}
        title={t("component.revoke-api-key.before-change-shield-version.dialog.title")}
        content={t("component.revoke-api-key.before-change-shield-version.dialog.message")}
      />
    </>
  );
};

const useStyles = makeStyles()(() => ({
  link: {
    padding: 0,
  },
}));
